import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Grid, CircularProgress } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';

import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';

import CustomHeader from '~/components/CustomHeader';

import DeleteCart from './DeleteCart';
import RemoveProduct from './RemoveProduct';
import ShareCart from './ShareCart';
import CartData from './CartData';
import ButtonsContainer from './ButtonsContainer';
import ProductCard from './ProductCard';

import SnackBar from '~/util/SnackBar';

import api, { maisCarrinhoApi } from '../../../../services/api';

import * as Style from './styles';

function DetailCarts() {
    const profile = useSelector(state => state.user.profile);
    const history = useHistory();
    const location = useLocation();
    const selectedCart = location.state.selectedCart;

    // ************************************** VARIAVEIS DE ESTADO ******************************
    // VARIAVEL DE ESTADO QUE CONTROLA O LOADIND DA PAGINA
    const [loaded, setLoaded] = useState(false);
    const cartBrand = selectedCart.id_label;

    //  VARIAVEIS DE ESTADO QUE SETAM AS CAIXAS DE DIALOGO
    const [deleteCartDialogOpen, setDeleteCartDialogOpen] = useState(false);
    const [removeProductDialog, setRemoveProductDialog] = useState(false);
    const [shareCartDialogOpen, setShareCartDialogOpen] = useState(false);

    // VARIAVEL DE ESTADO QUE REPRESENTA O ATUAL CARRINHO QUE ESTA SENDO MANIPULADO
    const [updatedSelectedCart, setUpdatedSelectedCart] = useState({});

    const [productToBeRemoved, setProductToBeRemoved] = useState();
    const [removeProduct, setRemoveProduct] = useState(false);
    const [productName, setProductName] = useState('');

    // VARIAVEL DE ESTADO QUE SETA O LINK PARA O COMPARTILHAMENTO DO CARRINHO
    const [link, setLink] = useState('');

    // VARIAVEL DE ESTADO QUE SETA O VALOR TOTAL DO CARRINHO
    const [amount, setAmount] = useState();

    // VARIAVEL DE ESTADO QUE GUARDA O LINK DO CARRINHO COMPARTILHADO
    const [shareCartUrl, setShareCartUrl] = useState('');

    // ********************************* FUNÇÕES ***************************************

    function getShortUrl(originalUrl) {
        const originalUrlWithoutSpecialCharacters = originalUrl
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');
        const shortnerApi = `/url-shortner/${encodeURIComponent(
            originalUrlWithoutSpecialCharacters
        )}`;

        return maisCarrinhoApi
            .get(shortnerApi)
            .then(response => response.data.url.shortLink)
            .catch(() => {
                return originalUrl;
            });
    }

    function copyToClipboard(textToCopy) {
        let textArea;
        function createTextArea(text) {
            textArea = document.createElement('textArea');
            textArea.readOnly = true;
            textArea.contentEditable = true;
            textArea.value = text;
            document.body.appendChild(textArea);
        }
        function selectText() {
            textArea.select();
        }
        function copyTo() {
            document.execCommand('copy');
            document.body.removeChild(textArea);
        }
        createTextArea(textToCopy);
        selectText();
        copyTo();
    }

    // FUNÇÕES QUE BUSCAM OS PRODUTOS DE UM CARRINHO ESPECIFICO
    const getProductFromSelectedCart = useCallback(async selectedCartId => {
        setLoaded(false);
        try {
            const response = await maisCarrinhoApi.get(
                `/saved-cart/by-id-saved-cart?id_saved_cart=${selectedCartId}`
            );

            const cartStatusResponse = await api.get(
                `/mais-carrinho/status?id_mais_carrinho=${response.data[0].id_saved_cart}`
            );
            let cartStatus = 'PENDENTE';
            if (cartStatusResponse.data) {
                if (
                    cartStatusResponse.data[0]?.status &&
                    ['CAPTURADO', 'FATURADO'].includes(
                        cartStatusResponse.data[0].status
                    )
                ) {
                    cartStatus = 'FINALIZADO';
                }
            }
            response.data[0].status = cartStatus;
            setUpdatedSelectedCart(response.data[0]);
            // setBrand(response.data[0].id_label);

            const soma = response.data[0].saved_cart_products.reduce(
                (acc, valor_total) => {
                    return acc + valor_total.price;
                },
                0
            );
            setAmount(soma);
            setRemoveProduct(false);
        } catch (err) {
            SnackBar.error('Erro ao carregar carrinho');
        }
        setLoaded(true);
    }, []);

    useEffect(() => {
        getProductFromSelectedCart(selectedCart.id_saved_cart);
    }, [getProductFromSelectedCart, selectedCart.id_saved_cart, removeProduct]);

    // ------------------------------------------------------------------------------------------------

    // FUNCOES QUE GERAM O COMPARTILHAMENTO DE CARRINHO
    useEffect(() => {
        const getAndSetShareCartUrl = async () => {
            if (updatedSelectedCart?.saved_cart_products) {
                const getURLByBrandId = {
                    1: 'https://www.animale.com.br',
                    2: 'https://www.farmrio.com.br',
                    3: 'https://www.abrand.com.br',
                    5: 'https://www.afabula.com.br',
                    6: 'https://secure.offpremium.com.br',
                    7: 'https://www.foxtonbrasil.com.br',
                    9: 'https://secure.crisbarros.com.br',
                    15: 'https://secure.mariafilo.com.br',
                };

                const sc = 1;
                let checkoutParams = `sc=${sc}&utmi_cp=codigodavendedora&utm_medium=linkvendedor`;
                checkoutParams +=
                    '&utm_source=maisvendas&utm_campaign=' +
                    `${profile.users_sales_code[0].codigo_vendedor} - ${profile.nome}`;
                updatedSelectedCart.saved_cart_products.forEach(item => {
                    checkoutParams += `&sku=${item.id_product_color}&qty=1&seller=${item.seller}`;
                });
                const updatedSelectedCartId = updatedSelectedCart.id_saved_cart;
                checkoutParams += `&utmi_pc=${updatedSelectedCartId}`;
                const baseUrl = getURLByBrandId[cartBrand];

                const newShareCartUrl = await getShortUrl(
                    `${baseUrl}/checkout/cart/add?${checkoutParams}`
                );
                setShareCartUrl(newShareCartUrl);
            }
        };
        getAndSetShareCartUrl();
        // return `${storeUrl}/checkout/cart/add?${checkoutParams}`;
    }, [
        cartBrand,
        profile.nome,
        profile.users_sales_code,
        updatedSelectedCart.id_saved_cart,
        updatedSelectedCart.saved_cart_products,
    ]);

    async function shareCart() {
        const shareUrl = shareCartUrl;

        if (shareUrl) {
            if (navigator.share) {
                const shareData = {
                    title: 'Link Compartilhado',
                    text: 'Link do Carrinho:',
                    url: shareUrl,
                };
                try {
                    navigator.share(shareData);
                } catch (err) {
                    window.alert(err);
                }
            } else {
                try {
                    copyToClipboard(shareUrl);
                } catch (e) {
                    SnackBar.error('Erro ao copiar o Link');
                }
                setShareCartDialogOpen(true);
            }

            setLink(shareUrl);
            // setShareCartDialogOpen(true);
        }
    }

    return (
        <>
            <CustomHeader
                goBackPage={() => history.push('/maisCarrinho')}
                title={updatedSelectedCart.cart_name}
            />
            <Style.PageContentContainer>
                {loaded ? (
                    <Style.PageRoot container>
                        <Grid item xs={12}>
                            <CartData
                                updatedSelectedCart={updatedSelectedCart}
                                amount={amount}
                            />
                            {/* ******************************************* */}
                            <ButtonsContainer
                                selectedCart={updatedSelectedCart}
                                setDeleteCartDialogOpen={
                                    setDeleteCartDialogOpen
                                }
                            />
                            {/* ******************************************* */}
                            <Style.SecondaryTitle>Itens</Style.SecondaryTitle>
                            <ProductCard
                                updatedSelectedCart={updatedSelectedCart}
                                setProductToBeRemoved={setProductToBeRemoved}
                                setRemoveProductDialog={setRemoveProductDialog}
                                setProductName={setProductName}
                            />
                        </Grid>
                        <Style.ButtonContainer>
                            {updatedSelectedCart?.saved_cart_products
                                ?.length ? (
                                <>
                                    {shareCartUrl ? (
                                        <Style.ShareButton
                                            fullWidth
                                            xs={12}
                                            onClick={() => {
                                                shareCart();
                                            }}
                                        >
                                            <ShareOutlinedIcon />
                                            Compartilhar
                                        </Style.ShareButton>
                                    ) : (
                                        <Style.ShareButton fullWidth>
                                            <CircularProgress
                                                size={30}
                                                style={{
                                                    color: '#212121',
                                                }}
                                            />
                                        </Style.ShareButton>
                                    )}
                                </>
                            ) : (
                                <Style.ShareButton secondary xs={12} fullWidth>
                                    <ShareOutlinedIcon />
                                    Compartilhar
                                </Style.ShareButton>
                            )}
                        </Style.ButtonContainer>
                    </Style.PageRoot>
                ) : (
                    <Grid
                        container
                        justifyContent="center"
                        style={{ paddingTop: '32px' }}
                    >
                        <CircularProgress
                            size={50}
                            style={{
                                color: '#DCC6B7',
                            }}
                        />
                    </Grid>
                )}
            </Style.PageContentContainer>

            {/* ************************************ CAIXAS DE DIALOGOS DA PAGINA DETAIL CARTS **************************************** */}

            {/* CAIXA DE DIALOGO DE EXCLUIR CARRINHO */}
            <DeleteCart
                deleteCartDialogOpen={deleteCartDialogOpen}
                setDeleteCartDialogOpen={setDeleteCartDialogOpen}
                selectedCart={updatedSelectedCart}
            />

            {/* CAIXA DE DIALOGO DE REMOVER PRODUTO */}
            <RemoveProduct
                removeProductDialog={removeProductDialog}
                setRemoveProductDialog={setRemoveProductDialog}
                setRemoveProduct={setRemoveProduct}
                updatedSelectedCart={updatedSelectedCart}
                setProductName={setProductName}
                productName={productName}
                productToBeRemoved={productToBeRemoved}
            />

            {/* CAIXA DE DIALOGO DE COMPARTILHAR CARRINHO */}
            <ShareCart
                shareCartDialogOpen={shareCartDialogOpen}
                setShareCartDialogOpen={setShareCartDialogOpen}
                link={link}
            />
        </>
    );
}

export default DetailCarts;
