import { ESTOQUISTA_SOMA } from '~/util/perfilId';

const ANIMALE = 1;
const FABULA = 5;
const OFFPREMIUM = 6;
const FOXTON = 7;
const MARIA_FILO = 15;
const NV = 16;
const CRIS_BARROS = 9;
const FARM = 2;
const NVEcommerce = '805209';

const allowedBrands = [
    ANIMALE,
    FABULA,
    OFFPREMIUM,
    FOXTON,
    MARIA_FILO,
    NV,
    CRIS_BARROS,
    FARM,
];

const getSomaStoreAccessFromUserProfile = userProfile => {
    const userBranch = userProfile?.users_branches?.map(userBranchObj =>
        userBranchObj.codigo_filial.length === 3
            ? `000${userBranchObj.codigo_filial}`
            : userBranchObj.codigo_filial
    )[0];

    const userProfileCode = userProfile?.id_perfil;

    if (userProfileCode === ESTOQUISTA_SOMA) {
        return false;
    }

    if (userBranch === NVEcommerce) return true;

    return allowedBrands.includes(userProfile.marcaFilial);
};
export default getSomaStoreAccessFromUserProfile;
