import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { Grid, SwipeableDrawer } from '@mui/material';
import generateMenu from '~/util/generateMenu';
import api, { apiV2 } from '~/services/api';
import ListIconPiloto from '../ListIconPiloto';
import { signOut } from '../../store/modules/auth/actions';

import 'moment/locale/pt-br';

import * as Style from './styles';

function LeftMenuDrawer({ openMenu, location, history, open, count }) {
    const user = useSelector(state => state.user.profile);
    const dispatch = useDispatch();
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

    function logOut() {
        dispatch(signOut());
    }

    const toggleDrawer = (side, open) => event => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [side]: open });
        openMenu();
    };

    const matchRoute = (urlTested, matcher) => {
        const urlSplitted = urlTested.split('/');
        const matchSplitted = matcher.split('/');
        return urlSplitted[1] === matchSplitted[1];
    };

    function redirect(url) {
        history.push(url);
    }

    const { data } = useQuery(
        'menus',
        async () => {
            const response = await api.get(`/menu`);
            const result = response.data?.map(value => {
                return {
                    ...value,
                    originalRoute: value.route,
                };
            });

            return result;
        },
        {
            refetchOnWindowFocus: false,
            staleTime: 1000 * 86400, // 24 horas
        }
    );

    const tokenLojinha = useQuery(
        'tokenLojinha',
        async () => {
            const response = await apiV2.get(
                `/lojinha?salesCode=${user.users_sales_code[0]?.codigo_vendedor}`
            );
            return response.data.token;
        },
        {
            refetchOnWindowFocus: false,
            staleTime: 1000 * 86400, // 24 horas
        }
    );

    const menuIcons = generateMenu({
        data,
        user,
        redirect,
        logOut,
        tokenLojinha: tokenLojinha.data,
    });

    const handleClick = icon => {
        icon.action();
    };
    function getIndexInitialSelectedMenu(iconsMenu) {
        const urlNow = location.pathname;
        return iconsMenu
            .filter(menu => menu.show)
            .filter(menu => menu.position !== 'bottom')
            .findIndex(menu => {
                if (menu.route) {
                    return matchRoute(menu.route, urlNow);
                }
                return false;
            });
    }

    const renderUserInfo = () => {
        return (
            <Style.UserInfoContainer container>
                <Grid item>
                    <Style.MenuAvatar>
                        {user &&
                            user?.nome
                                .split(' ')
                                .map(word => word[0])
                                .slice(0, 2)
                                .join('')}
                    </Style.MenuAvatar>
                </Grid>
                <Grid item>
                    <Style.Login>{user && user.nome.toLowerCase()}</Style.Login>
                </Grid>
                <Grid item>
                    <Style.SalesCode>
                        {user &&
                            (user?.users_sales_code[0]?.codigo_vendedor ||
                                user?.users_branches[0]?.codigo_filial)}
                    </Style.SalesCode>
                </Grid>
            </Style.UserInfoContainer>
        );
    };

    const sideList = side => {
        const menuIconsWithEvents = menuIcons.map(menuIcon => ({
            ...menuIcon,
            action: () => {
                menuIcon.action();
            },
        }));
        return (
            <Style.ListComponent
                role="presentation"
                onClick={toggleDrawer(side, true)}
                onKeyDown={toggleDrawer(side, false)}
            >
                {renderUserInfo()}
                <ListIconPiloto
                    icons={menuIconsWithEvents.filter(icon => icon.show)}
                    onClick={handleClick}
                    showText={open}
                    showSelected
                    initialIndexSelect={getIndexInitialSelectedMenu(
                        menuIconsWithEvents
                    )}
                    count={count}
                />
            </Style.ListComponent>
        );
    };

    return (
        <div>
            <SwipeableDrawer
                disableBackdropTransition={!iOS}
                disableDiscovery={iOS}
                open={open}
                onClose={toggleDrawer('left', false)}
                onOpen={toggleDrawer('left', true)}
            >
                {sideList('left')}
            </SwipeableDrawer>
        </div>
    );
}
export default withRouter(LeftMenuDrawer);
