import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
    Grid,
    Typography,
    Divider,
    CircularProgress,
    CardContent,
    List,
    ListItem,
    Box,
} from '@mui/material';

import moment from 'moment';
import DateRangePicker from '~/components/DateRangePicker';
import { formatPrice } from '~/util/format';
import Snackbar from '~/util/SnackBar';
import Header from '~/components/Header';

import api from '~/services/api';
import {
    changeFinalDate,
    changeInitialDate,
    changeDatePeriod,
} from '~/store/modules/status/actions';
import getAssessmentDatesByDate from '~/util/getAssessmentDatesByDate';
import SalesPersonListItem from './SalesPersonListItem';
import * as Style from './styles';

function ConsultarCotas() {
    const userProfile = useSelector(state => state.user.profile);
    const [salesCard, setSalesCard] = useState(undefined);
    const [quotaValues, setQuotaValues] = useState(undefined);
    const [salesTeamData, setSalesTeamData] = useState([]);
    const { initialDate, finalDate } = useSelector(state => state.status);

    const [loadingQuotaPanel, setLoadingQuotaPanel] = useState(true);
    const [loadingSalesTeamData, setLoadingSalesTeamData] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(changeDatePeriod('month'));
        let assessmentDate = moment().endOf('month');

        if (moment().date() > 25) {
            assessmentDate = assessmentDate.add(1, 'month');
        }
        const { startDate, endDate } = getAssessmentDatesByDate(assessmentDate);

        dispatch(changeInitialDate(startDate));
        dispatch(changeFinalDate(endDate));
    }, [dispatch]);

    const checkIfCanMakeRequests = useCallback(() => {
        return (
            initialDate &&
            finalDate &&
            moment(finalDate).diff(initialDate, 'days') !== 0
        );
    }, [finalDate, initialDate]);

    useEffect(() => {
        if (checkIfCanMakeRequests()) {
            const getQuota = async () => {
                const quotaResponse = await api.get('/store/quota', {
                    params: {
                        startDate: moment(initialDate).format('YYYY-MM-DD'),
                        endDate: moment(finalDate).format('YYYY-MM-DD'),
                        filialCode:
                            userProfile.users_branches[0].codigo_filial
                                .length === 3
                                ? `000${userProfile.users_branches[0].codigo_filial}`
                                : userProfile.users_branches[0].codigo_filial,
                    },
                });
                setSalesCard(currentSalesCardData => ({
                    ...currentSalesCardData,
                    secondaryText: formatPrice(
                        `${quotaResponse.data.totalQuota}`
                    ),
                }));
                setQuotaValues(currentQuotaValue => ({
                    ...currentQuotaValue,
                    quota: quotaResponse.data.totalQuota,
                    dailyQuotas: quotaResponse.data.dailyQuotas,
                }));

                setLoadingQuotaPanel(false);
            };

            getQuota();
        }
    }, [
        checkIfCanMakeRequests,
        finalDate,
        initialDate,
        userProfile,
    ]);

    useEffect(() => {
        const getRankingData = async () => {
            try {
                if (initialDate && finalDate && quotaValues) {
                    setLoadingSalesTeamData(true);
                    const rankingResponse = await api.get(
                        '/store-manager/sales-team',
                        {
                            params: {
                                dateInit: moment(initialDate).format(
                                    'YYYY-MM-DD'
                                ),
                                dateEnd: moment(finalDate).format('YYYY-MM-DD'),
                                filialCode:
                                    userProfile.users_branches[0].codigo_filial
                                        .length === 3
                                        ? `000${userProfile.users_branches[0].codigo_filial}`
                                        : userProfile.users_branches[0]
                                              .codigo_filial,
                                filialBrandId: userProfile.marcaFilial,
                            },
                        }
                    );
                    const newRankingData = rankingResponse.data.map(
                        salesperson => ({
                            ...salesperson,
                            shortName: `${
                                salesperson.vendedor_apelido.split(' ')[0]
                            } ${
                                salesperson.vendedor_apelido.split(' ')[
                                    salesperson.vendedor_apelido.split(' ')
                                        .length - 1
                                ]
                            }`,
                            quotaValueMonth:
                                Math.round(salesperson.quota * 100) / 100 || 0,
                            quotaPercentageMonth:
                                (salesperson.quota / quotaValues?.quota) *
                                    100 || 0,
                        })
                    );
                    setSalesTeamData(newRankingData);
                }
            } catch (e) {
                setSalesTeamData([]);
                // eslint-disable-next-line no-console
                console.log(e);
                Snackbar.warning('Falha ao buscar a lista de vendedores');
            } finally {
                setLoadingSalesTeamData(false);
            }
        };
        getRankingData();
    }, [finalDate, initialDate, quotaValues, setSalesTeamData, userProfile]);

    return (
        <>
            <Header />

            <Style.Main container>
                <Typography
                    style={{
                        fontWeight: 600,
                        fontSize: '24px',
                        margin: '16px',
                    }}
                >
                    Consultar Cotas
                </Typography>
                <Grid item xs={12}>
                    <Style.BrandContainer container>
                        <Grid item xs={12}>
                            <Style.PrimaryTypography>
                                <DateRangePicker
                                    loading={
                                        loadingSalesTeamData ||
                                        loadingQuotaPanel
                                    }
                                    assessment
                                    fixedPeriod
                                />
                            </Style.PrimaryTypography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider variant="fullWidth" />
                        </Grid>

                        {loadingQuotaPanel ? (
                            <Grid
                                container
                                xs={12}
                                style={{
                                    justifyContent: 'center',
                                }}
                            >
                                <CircularProgress />
                            </Grid>
                        ) : (
                            <Grid item xs={12}>
                                <Grid container justifyContent="space-between">
                                    <Grid item>
                                        <Style.PrimaryTypography>
                                            Cota da Loja
                                        </Style.PrimaryTypography>
                                    </Grid>
                                    <Grid item>
                                        <Style.PrimaryTypography>
                                            {salesCard?.secondaryText}
                                        </Style.PrimaryTypography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Style.BrandContainer>
                </Grid>
            </Style.Main>

            <Grid container>
                <Grid item xs={12} style={{ margin: '16px' }}>
                    <Box style={{ backgroundColor: '#373435' }}>
                        <Grid
                            container
                            justifyContent="space-between"
                            style={{ padding: '16px' }}
                        >
                            <Grid item xs={12}>
                                <Typography
                                    style={{
                                        fontWeight: 600,
                                        fontSize: '20px',
                                    }}
                                >
                                    Cotas da Equipe
                                </Typography>
                            </Grid>
                        </Grid>

                        <CardContent
                            style={{
                                paddingTop: '0px',
                                textAlign: 'center',
                            }}
                        >
                            {loadingSalesTeamData ? (
                                <CircularProgress />
                            ) : (
                                <List>
                                    {salesTeamData.length !== 0 ? (
                                        <SalesPersonListItem
                                            salesTeamData={salesTeamData}
                                            initialDate={initialDate}
                                            finalDate={finalDate}
                                        />
                                    ) : (
                                        <ListItem
                                            style={{
                                                height: '72px',
                                                borderTop: '1px solid #9E9E9E',
                                                borderBottom:
                                                    '1px solid #9E9E9E',
                                                placeContent: 'center',
                                            }}
                                        >
                                            Sem Vendedores
                                        </ListItem>
                                    )}
                                </List>
                            )}
                        </CardContent>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

export default ConsultarCotas;
