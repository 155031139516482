import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import Header from '~/components/Header';
import CustomHeader from '~/components/CustomHeader';
import NPSForm from '~/components/NPSForm';
import SalesTerms from '~/components/SalesTerms';
import api from '~/services/api';
import Snackbar from '~/util/SnackBar';
import {
    onlineChannels,
    physicalChannels,
} from '~/util/mapChannelsPhysicalAndOnline';

import { formatWithCent } from '~/util/format';
import DateRangePicker from '~/components/DateRangePicker';
import { TREINADOR_ADOROCODIGO, VENDEDOR_ADOROCODIGO } from '~/util/perfilId';
import { handleOrdersResponse } from '~/util/handleOrdersResponse';
import getDateRange from '~/util/getDateRange';
import { changeShouldShowNPS } from '../../store/modules/user/actions';
import { signOut } from '../../store/modules/auth/actions';
import DesktopLayout from './DesktopLayout';

function Cota({ location }) {
    const dispatch = useDispatch();
    const [capturedOrdersIndicators, setCapturedOrdersIndicators] = useState(
        {}
    );
    const [indicatorsText, setIndicatorsText] = useState({});
    const [orders, setOrders] = useState([]);
    const [ordersLoaded, setOrdersLoaded] = useState(false);
    const [panelLoaded, setPanelLoaded] = useState(false);

    const [chartData, setChartData] = useState([]);
    const [showNPSForm, setShowNPSForm] = useState(false);
    const [shouldLogout, setShouldLogout] = useState(false);
    const [brandFilter, setBrandFilter] = useState('allBrands');
    const [aggregatedOrdersByBrand, setAggregatedOrdersByBrand] = useState({});

    const multimarca = true;

    const userProfileFromStore = useSelector(state => state.user.profile);
    const [userProfile, setUserProfile] = useState(undefined);
    const { initialDate, finalDate, isFaturado } = useSelector(
        state => state.status
    );
    const dateRange = getDateRange(initialDate, finalDate, 'DD/MM/YYYY');
    const [salesCodeFromParams, setSalesCodeFromParams] = useState('');

    const query = new URLSearchParams(location.search);
    const salesCode = query.get('salesCode');

    async function handleSubmit() {
        try {
            await api.post('/nps', {
                answer: '',
                comment: '',
                isSalesperson: userProfile.isSalesperson,
                login: userProfile.isSalesperson
                    ? userProfile.salesCode
                    : userProfile.login,
            });

            dispatch(changeShouldShowNPS(false));
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    }

    useEffect(() => {
        setSalesCodeFromParams(salesCode);
        if (
            salesCode &&
            [6, 7, 8, 9, 10, 11, 12, 13, 15, 16, 17, 18].includes(
                userProfileFromStore.id_perfil
            )
        ) {
            const salesCodeFromQueryParams = salesCode.toUpperCase();
            const getUserData = async () => {
                const response = await api.post(`session/user-data`, {
                    salesCode: salesCodeFromQueryParams,
                });
                setUserProfile(response.data.user);
            };
            getUserData();
        } else {
            setUserProfile(userProfileFromStore);
        }
        // eslint-disable-next-line react/destructuring-assignment
    }, [location.search, salesCode, userProfileFromStore]);
    useEffect(() => {
        if (shouldLogout) {
            dispatch(signOut());
        }
    }, [dispatch, shouldLogout]);

    const checkIfCanMakeRequests = useCallback(() => {
        return finalDate && userProfile?.users_sales_code[0].codigo_vendedor;
    }, [finalDate, userProfile]);

    useEffect(() => {
        const isFarm = userProfile?.marcaFilial === 2;
        const beforeDateLimit = new Date() < new Date('2023-05-26');
        const shouldAnswerNPS = userProfile?.shouldAnswerNPS;

        if (shouldAnswerNPS) {
            if (!(beforeDateLimit && isFarm)) {
                setShowNPSForm(userProfile.shouldAnswerNPS);
            }
        }
    }, [userProfile]);

    useEffect(() => {
        const newIndicatorsText = {
            totalValue: 0,
            totalAverageValue: 0,
            totalOrderQuantity: 0,
            totalProductQuantity: 0,
            totalAverageQuantity: 0,

            onlineTotalValue: 0,
            onlineAverageValue: 0,
            onlineAverageQuantity: 0,
            onlineOrderQuantity: 0,
            onlineProductQuantity: 0,

            physicalTotalValue: 0,
            physicalAverageValue: 0,
            physicalAverageQuantity: 0,
            physicalOrderQuantity: 0,
            physicalProductQuantity: 0,
        };

        const ordersIndicators = capturedOrdersIndicators;

        Object.entries(ordersIndicators).forEach(
            ([indicatorChannel, indicatorValue]) => {
                if (onlineChannels.includes(indicatorChannel)) {
                    newIndicatorsText.onlineTotalValue += indicatorValue.value;
                    newIndicatorsText.onlineOrderQuantity +=
                        indicatorValue.orderQuantity;
                    newIndicatorsText.onlineProductQuantity +=
                        indicatorValue.productQuantity;
                    newIndicatorsText.totalValue += indicatorValue.value;
                    newIndicatorsText.totalOrderQuantity +=
                        indicatorValue.serviceQuantity;
                    newIndicatorsText.totalProductQuantity +=
                        indicatorValue.productQuantity;
                }
                if (physicalChannels.includes(indicatorChannel)) {
                    newIndicatorsText.physicalTotalValue +=
                        indicatorValue.value;
                    newIndicatorsText.physicalOrderQuantity +=
                        indicatorValue.orderQuantity;
                    newIndicatorsText.physicalProductQuantity +=
                        indicatorValue.productQuantity;
                    newIndicatorsText.totalValue += indicatorValue.value;
                    newIndicatorsText.totalOrderQuantity +=
                        indicatorValue.serviceQuantity;
                    newIndicatorsText.totalProductQuantity +=
                        indicatorValue.productQuantity;
                }
            }
        );

        newIndicatorsText.onlineAverageQuantity = (
            newIndicatorsText.onlineProductQuantity /
                newIndicatorsText.onlineOrderQuantity || 0
        ).toFixed(2);

        newIndicatorsText.onlineAverageValue =
            newIndicatorsText.onlineTotalValue /
                newIndicatorsText.onlineOrderQuantity || 0;

        newIndicatorsText.physicalAverageQuantity = (
            newIndicatorsText.physicalProductQuantity /
                newIndicatorsText.physicalOrderQuantity || 0
        ).toFixed(2);

        newIndicatorsText.physicalAverageValue =
            newIndicatorsText.physicalTotalValue /
                newIndicatorsText.physicalOrderQuantity || 0;

        newIndicatorsText.totalAverageValue =
            newIndicatorsText.totalValue /
                newIndicatorsText.totalOrderQuantity || 0;

        newIndicatorsText.totalAverageQuantity = (
            newIndicatorsText.totalProductQuantity /
                newIndicatorsText.totalOrderQuantity || 0
        ).toFixed(2);

        const canFormat = [
            'onlineTotalValue',
            'physicalTotalValue',
            'totalValue',
            'totalAverageValue',
            'physicalAverageValue',
            'onlineAverageValue',
        ];

        Object.keys(newIndicatorsText).forEach(key => {
            if (canFormat.includes(key)) {
                newIndicatorsText[key] = formatWithCent(newIndicatorsText[key]);
            }
        });

        setIndicatorsText(newIndicatorsText);
    }, [capturedOrdersIndicators, isFaturado]);

    const getBrandsParamsForRequests = useCallback(() => {
        switch (brandFilter) {
            case 'Animale':
                return [1];
            case 'Farm':
                return [2];
            case 'A.Brand':
                return [3];
            case 'Fábula':
                return [5];
            case 'OffPremium':
                return [6];
            case 'Foxton':
                return [7];
            case 'Cris Barros':
                return [9];
            case 'Maria Filó':
                return [15];
            case 'allBrands':
                return [2, 5, 7];
            default:
                return [];
        }
    }, [brandFilter]);

    const userBrandParam = () => {
        if (salesCode || userProfile.id_perfil === VENDEDOR_ADOROCODIGO) {
            return [2];
        }
        return getBrandsParamsForRequests();
    };

    useEffect(() => {
        if (checkIfCanMakeRequests()) {
            setPanelLoaded(false);
            const getAggregatedCapturedOrders = async () => {
                try {
                    const brandParamForRequest = userBrandParam();

                    const requestParams = {
                        fatured: 'false',
                        startDate: moment(initialDate).format('YYYY-MM-DD'),
                        endDate: moment(finalDate).format('YYYY-MM-DD'),
                        salesCode: userProfile.users_sales_code.map(
                            salesCodeObj => salesCodeObj.codigo_vendedor
                        ),
                        transaction: 'true',
                        brands: brandParamForRequest,
                    };

                    const response = await api.get(
                        `/salesperson/aggregated-orders`,
                        {
                            params: requestParams,
                        }
                    );

                    setCapturedOrdersIndicators(
                        response.data.data.aggregatedByChannel
                    );

                    setAggregatedOrdersByBrand(
                        response.data.data.aggregatedByBrand
                    );

                    setChartData(
                        getDateRange(initialDate, finalDate, 'YYYY-MM-DD').map(
                            date => ({
                                value:
                                    response.data.data.aggregatedByDay[
                                        date.label
                                    ]?.value,
                                label: date.formattedLabel,
                            })
                        )
                    );

                    if (response.data.msg) {
                        Snackbar.info(response.data.msg);
                    }
                } catch {
                    Snackbar.error(
                        'Erro retornando os indicadores de pedidos capturados'
                    );
                }
            };

            Promise.all([getAggregatedCapturedOrders()])
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        Snackbar.error('Token expirado. Faça login novamente');
                        setShouldLogout(true);
                    }
                })
                .finally(() => setPanelLoaded(true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        finalDate,
        checkIfCanMakeRequests,
        userProfile,
        isFaturado,
        getBrandsParamsForRequests,
    ]);

    useEffect(() => {
        setOrders([]);
        if (checkIfCanMakeRequests()) {
            setOrdersLoaded(false);
            const getSalespersonOrders = async () => {
                try {
                    const brandParamForRequest = userBrandParam();

                    const requestParams = {
                        startDate: moment(initialDate).format('YYYY-MM-DD'),
                        endDate: moment(finalDate).format('YYYY-MM-DD'),
                        salesCode: userProfile.users_sales_code.map(
                            salesCodeObj => salesCodeObj.codigo_vendedor
                        ),
                        orderType: isFaturado ? 'invoiced' : 'captured',
                        brands: brandParamForRequest,
                        transaction: 'true',
                    };

                    const response = await api.get(`salesperson/orders`, {
                        params: requestParams,
                    });

                    const formattedOrders = await handleOrdersResponse(
                        response.data.data,
                        false
                    );

                    setOrders(formattedOrders);
                    setOrdersLoaded(true);
                } catch {
                    Snackbar.error('Erro retornando a lista de pedidos');
                    setOrders([]);
                }
                setOrdersLoaded(true);
            };
            getSalespersonOrders();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        finalDate,
        checkIfCanMakeRequests,
        userProfile,
        isFaturado,
        brandFilter,
    ]);

    return (
        <>
            <NPSForm
                open={showNPSForm}
                onClose={() => {
                    setShowNPSForm(false);
                    handleSubmit();
                }}
            />
            <SalesTerms />
            {location.search.includes('salesCode') ? (
                <CustomHeader
                    title={
                        userProfile &&
                        `${userProfile?.nome
                            .split(' ')[0]
                            .toLowerCase()} (${salesCodeFromParams})`
                    }
                />
            ) : (
                <Header />
            )}
            {userProfileFromStore.id_perfil === TREINADOR_ADOROCODIGO ? (
                <DateRangePicker hidden={!panelLoaded} assessment />
            ) : (
                <DateRangePicker hidden={!panelLoaded} />
            )}
            {panelLoaded || (
                <Grid
                    container
                    justifyContent="center"
                    spacing={1}
                    style={{ paddingTop: '10px' }}
                >
                    <Grid container justifyContent="center" item xs={12}>
                        <Skeleton animation="wave" width="150px" />
                    </Grid>
                    <Grid container justifyContent="center" item xs={12}>
                        <Skeleton animation="wave" width="200px" />
                    </Grid>
                </Grid>
            )}
            <DesktopLayout
                indicatorsText={indicatorsText}
                panelLoaded={panelLoaded}
                chartData={chartData}
                dateRange={dateRange}
                multimarca={multimarca}
                ordersLoaded={ordersLoaded}
                orders={orders}
                brandFilter={brandFilter}
                setBrandFilter={setBrandFilter}
                aggregatedOrdersByBrand={aggregatedOrdersByBrand}
            />
        </>
    );
}

export default Cota;
