import { FARM, ANIMALE, FABULA, OFFPREMIUM, FOXTON, CRISBARROS, MARIAFILO } from "./brands";
import { INFLUENCIADORES_OFF, TREINADOR_ADOROCODIGO } from "./perfilId";

const getAvailableBrands = brandId => {
    if ([FABULA, OFFPREMIUM, FOXTON, CRISBARROS, MARIAFILO].includes(brandId)) {
        return [brandId];
    }
    if (brandId === ANIMALE) return [ANIMALE, OFFPREMIUM];
    if (brandId === FARM) return [FARM, FABULA, FOXTON];
    return [ANIMALE, FARM, FABULA, OFFPREMIUM, FOXTON, CRISBARROS, MARIAFILO];
};

const getCartBrandSettings = profile => {
    const isCorporateUser = [12, 52].includes(profile.marcaFilial);
    let availableBrands = [];
    let defaultCartBrand;
    if (profile.marcaFilial && !isCorporateUser) {
        defaultCartBrand = profile.marcaFilial;
        availableBrands = getAvailableBrands(profile.marcaFilial);
        return { defaultCartBrand, availableBrands };
    }
    if (isCorporateUser) {
        availableBrands = getAvailableBrands(profile.marcaFilial);
        return { defaultCartBrand, availableBrands };
    }
    if (profile.marcaFilialMultimarca) {
        if (profile.id_perfil === INFLUENCIADORES_OFF) {
            // INFLUENCIADOR OFF
            availableBrands = [ANIMALE, FARM, FABULA, OFFPREMIUM, FOXTON, CRISBARROS];
        } else {
            availableBrands = profile.marcaFilialMultimarca.filter(
                item => item !== OFFPREMIUM
            ); // MULTIMARCA
        }
        return { defaultCartBrand, availableBrands };
    }
    defaultCartBrand = FARM;

    if (profile.id_perfil === TREINADOR_ADOROCODIGO) {
        availableBrands = [FARM, FABULA, OFFPREMIUM, FOXTON];
    } else {
        availableBrands = [FARM];
    }

    return { defaultCartBrand, availableBrands };
};

export { getCartBrandSettings };
