import React from 'react';
import { useSelector } from 'react-redux';
import { Divider } from '@mui/material';

import ChangeToChartButton from '../ChangeToChart';
import PanelBody from './PanelBody';

import { formatPrice } from '~/util/format';
import { getBrandNameFromBrandId } from '~/util/brands';

import * as Style from './styles';

function SalesPersonCard({
    balanceText,
    aggregatedOrdersByBrand,
    showIcon,
    handleFlipped,
}) {
    const userProfile = useSelector(state => state.user.profile);

    const userBrands = [userProfile.marcaFilial];
    const myBrandNames = userBrands.map(brandId =>
        getBrandNameFromBrandId(brandId)
    );

    const getQuotaByBrands = () => {
        let brandQuotaData = {
            myBrand: 0,
            otherBrands: 0,
        };

        if (aggregatedOrdersByBrand) {
            Object.keys(aggregatedOrdersByBrand).forEach(brandId => {
                const brandIdAsNumber = Number(brandId);
                if (userBrands.includes(brandIdAsNumber)) {
                    brandQuotaData.myBrand +=
                        aggregatedOrdersByBrand[brandIdAsNumber].value;
                } else {
                    brandQuotaData.otherBrands +=
                        aggregatedOrdersByBrand[brandIdAsNumber].value;
                }
            });
        }

        return brandQuotaData;
    };
    const quotaByBrands = getQuotaByBrands();

    const panelData = {
        myBrand: {
            label: `${myBrandNames.join(', ')}`,
            value: formatPrice(quotaByBrands.myBrand),
        },
        otherBrands: {
            label: 'Outras marcas',
            value: formatPrice(quotaByBrands.otherBrands),
        },
    };

    return (
        <Style.PanelCardContainer>
            <ChangeToChartButton
                showIcon={showIcon}
                handleFlipped={handleFlipped}
            />
            <Style.BalanceTextTypography primary>
                {balanceText}
            </Style.BalanceTextTypography>
            <PanelBody
                brands={panelData?.myBrand?.label}
                countsForQuota={true}
                value={panelData?.myBrand?.value}
            />
            <Divider style={{ height: '2px' , margin: '1rem 0'}} />
            <PanelBody
                brands={panelData?.otherBrands?.label}
                countsForQuota={false}
                value={panelData?.otherBrands?.value}
            />
        </Style.PanelCardContainer>
    );
}

export default SalesPersonCard;
