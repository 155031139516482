import React, { useEffect, useState } from 'react';
import { Typography, Grid } from '@mui/material';

import { useHistory } from 'react-router-dom';

import { useSelector } from 'react-redux';
import Divider from '@mui/material/Divider';
import moment from 'moment';
import Snackbar from '~/util/SnackBar';

import MonthlyQuotaPanel from '~/components/MonthlyQuotaPanel';

import CustomHeader from '~/components/CustomHeader';
import api from '~/services/api';

import { formatPrice, abbreviateFormatDate } from '~/util/format';

import getAssessmentDatesByDate from '~/util/getAssessmentDatesByDate';
import * as Style from './styles';

let assessmentDate = moment().endOf('month');
if (
    moment().date() > 25 &&
    moment().isAfter(
        moment()
            .hour(0)
            .date(1)
            .month(4)
            .year(2021)
    )
) {
    assessmentDate = assessmentDate.add(1, 'month');
}

const initialDate = getAssessmentDatesByDate(assessmentDate).startDate;
const finalDate = getAssessmentDatesByDate(assessmentDate).endDate;

function CotasMensais({
    setCurrentPage,
    salesTeamData,
    setSalesTeamData,
    quotaValues,
    setQuotaValues,
}) {
    const history = useHistory();
    const [salesCard, setSalesCard] = useState(undefined);
    const [loadingSalesTeamData, setLoadingSalesTeamData] = useState(false);
    const [isAllQuotaDistributed, setIsAllQuotaDistributed] = useState(false);
    const [
        distributionModeInCurrency,
        setDistributionModeInCurrency,
    ] = useState(false);

    const userProfile = useSelector(state => state.user.profile);
    // const [filialCode] = useState(match.params.filialCode);

    useEffect(() => {
        if (distributionModeInCurrency) {
            const salespeopleQuotaSum =
                Math.round(
                    salesTeamData.reduce(
                        (total, salesperson) =>
                            total + Number(salesperson.quotaValueMonth || 0),
                        0
                    ) * 100
                ) / 100;
            const storeQuota = quotaValues?.quota;
            setIsAllQuotaDistributed(salespeopleQuotaSum === storeQuota);
        } else {
            const salespeopleQuotaPercentageSum = salesTeamData.reduce(
                (total, salesperson) =>
                    total + Number(salesperson.quotaPercentageMonth || 0),
                0
            );
            setIsAllQuotaDistributed(
                Number(salespeopleQuotaPercentageSum.toFixed(2)) === 100
            );
        }
    }, [distributionModeInCurrency, quotaValues, salesTeamData]);

    useEffect(() => {
        const getQuota = async () => {
            const quotaResponse = await api.get('/store/quota', {
                params: {
                    startDate: moment(initialDate).format('YYYY-MM-DD'),
                    endDate: moment(finalDate).format('YYYY-MM-DD'),
                    filialCode:
                        userProfile.users_branches[0].codigo_filial.length === 3
                            ? `000${userProfile.users_branches[0].codigo_filial}`
                            : userProfile.users_branches[0].codigo_filial,
                },
            });
            setSalesCard(currentSalesCardData => ({
                ...currentSalesCardData,
                secondaryText: formatPrice(`${quotaResponse.data.totalQuota}`),
            }));
            setQuotaValues(currentQuotaValue => ({
                ...currentQuotaValue,
                quota: quotaResponse.data.totalQuota,
                dailyQuotas: quotaResponse.data.dailyQuotas,
            }));
        };

        getQuota();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalDate]);

    useEffect(() => {
        const getRankingData = async () => {
            try {
                if (initialDate && finalDate && quotaValues) {
                    setLoadingSalesTeamData(true);
                    const rankingResponse = await api.get(
                        '/store-manager/sales-team',
                        {
                            params: {
                                dateInit: moment(initialDate).format(
                                    'YYYY-MM-DD'
                                ),
                                dateEnd: moment(finalDate).format('YYYY-MM-DD'),
                                filialCode:
                                    userProfile.users_branches[0].codigo_filial
                                        .length === 3
                                        ? `000${userProfile.users_branches[0].codigo_filial}`
                                        : userProfile.users_branches[0]
                                              .codigo_filial,
                                filialBrandId: userProfile.marcaFilial,
                            },
                        }
                    );

                    const newRankingData = rankingResponse.data.map(
                        salesperson => ({
                            ...salesperson,
                            shortName: `${
                                salesperson.vendedor_apelido.split(' ')[0]
                            } ${
                                salesperson.vendedor_apelido.split(' ')[
                                    salesperson.vendedor_apelido.split(' ')
                                        .length - 1
                                ]
                            }`,
                            quotaValueMonth:
                                Math.round((salesperson.quota * 100) / 100) ||
                                undefined,
                            quotaPercentageMonth:
                                ((salesperson.quota / quotaValues?.quota) *
                                    10000) /
                                    100 || undefined,
                            quotaInputLabel:
                                ((salesperson.quota / quotaValues?.quota) *
                                    10000) /
                                    100 || undefined,
                        })
                    );
                    setSalesTeamData(newRankingData);
                }
            } catch (e) {
                setSalesTeamData([]);
                // eslint-disable-next-line no-console
                console.log(e);
                Snackbar.warning('Falha ao buscar a lista de vendedores');
            } finally {
                setLoadingSalesTeamData(false);
            }
        };
        getRankingData();
    }, [quotaValues, setSalesTeamData, userProfile]);

    const handleClickOnNextPageButton = () => {
        setCurrentPage('CotasDiarias');
    };

    return (
        <>
            <CustomHeader title="Cadastro de Cotas" />
            <Grid
                container
                justifyContent="flex-start"
                alignItems="center"
                style={{
                    padding: '16px',
                }}
            >
                <Grid item xs={12}>
                    <Style.BrandContainer container>
                        <Grid item xs={12}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                            >
                                <Grid item>
                                    <Style.PrimaryTypography>
                                        Período
                                    </Style.PrimaryTypography>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        style={{
                                            fontSize: '16px',
                                        }}
                                    >
                                        {`${abbreviateFormatDate(
                                            moment(initialDate).format(
                                                'YYYY-MM-DD'
                                            )
                                        )} - ${abbreviateFormatDate(
                                            moment(finalDate).format(
                                                'YYYY-MM-DD'
                                            )
                                        )}, ${moment(finalDate).format(
                                            'YYYY'
                                        )}`}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider variant="fullWidth" />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                            >
                                <Grid item>
                                    <Style.PrimaryTypography>
                                        Cota da Loja
                                    </Style.PrimaryTypography>
                                </Grid>
                                <Grid item>
                                    <Style.PrimaryTypography>
                                        {salesCard?.secondaryText}
                                    </Style.PrimaryTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Style.BrandContainer>
                </Grid>
                <Grid
                    item
                    xs={12}
                    style={{
                        margin: '16px 12px',
                    }}
                >
                    <Style.PrimaryTypography primary>
                        Distribuição de cotas mensais
                    </Style.PrimaryTypography>
                </Grid>
                <Grid item xs={12}>
                    <MonthlyQuotaPanel
                        setSalesTeamData={setSalesTeamData}
                        salesTeamData={salesTeamData}
                        loadingSalesTeamData={loadingSalesTeamData}
                        quotaValues={quotaValues}
                        isAllQuotaDistributed={isAllQuotaDistributed}
                        distributionModeInCurrency={distributionModeInCurrency}
                        setDistributionModeInCurrency={
                            setDistributionModeInCurrency
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <Style.PrimaryButton
                        primary
                        onClick={handleClickOnNextPageButton}
                        disabled={!isAllQuotaDistributed}
                    >
                        Avançar
                    </Style.PrimaryButton>
                    <Style.PrimaryButton
                        onClick={() => {
                            history.push('/vendedores');
                        }}
                    >
                        Voltar
                    </Style.PrimaryButton>
                </Grid>
            </Grid>
        </>
    );
}

export default CotasMensais;
